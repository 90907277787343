
import { defineComponent } from "vue";
// import Loading from "@/components/Basic/Loading.vue";
// import { ElMessage, ElLoading } from "element-plus";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getApp } from "firebase/app";
// import {
//   getStorage,
//   ref,
//   uploadString,
// } from "firebase/storage";
// import _ from "lodash";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CreateAccount",
  // components: {
  //   Loading,
  // },
  async created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }

    const referralCode = helpers.getReferralCode();
    if (referralCode) {
      await this.$store.dispatch(
        "referral/validateReferralLink",
        referralCode,
        {
          root: true,
        }
      );
      if (this.$store.getters["referral/error"]) {
        this.$router.push({ name: "pageNotFound404" });
        return;
      }
      if (this.$store.getters["referral/data"]) {
        const data = this.$store.getters["referral/data"];
        const path = data.path || "";
        const owner = data.owner || "";
        if (path == "l/:referralCode" && owner) {
          this.form.email = owner;
        }
      }
    }

    /*
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const userData = localStorage.getItem("userData");
            if (!userData) {
              auth.signOut();
              this.$store.commit("auth/setUserLogin", null, { root: true });
            }
            this.$router.replace("/");
          } else {
            localStorage.removeItem("userData");
          }
        });
        //*/
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    // error(value) {
    //   ElMessage.error(value);
    //   console.error(value);
    // },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
    // error() {
    //   return this.$store.getters["auth/error"];
    // },
  },
  data() {
    return {
      disableButton: false,
      form: {
        email: "",
        acceptTerrms: true,
      },
      formValidate: {
        email: false,
        acceptTerrms: true,
      },
    };
  },
  mounted() {
    if (this.userLogin) {
      console.log("Your has been login!");
      this.$router.push("/");
    }
    this.setLangOnInit();
  },
  methods: {
    setLangOnInit() {
      const lang = this.$router.currentRoute.value.meta.lang || "EN";
      if (lang && lang == "TH") {
        (this as any).$i18n.setLocale("th");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      } else {
        (this as any).$i18n.setLocale("en");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      }
    },
    handleClickAuthProvider(provider: string) {
      this.signupByProvider(provider);
    },
    signupByProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
      };
      this.$store.dispatch("auth/signup", authData, { root: true });
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    async createFreeAcc() {
      /* eslint-disable */
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      /* eslint-enable */
      let validate = true;
      this.formValidate.email = false;
      this.formValidate.acceptTerrms = false;
      if (!email) {
        this.formValidate.email = true;
        validate = false;
      }
      if (!this.form.acceptTerrms) {
        this.formValidate.acceptTerrms = true;
        validate = false;
      }

      // @todo how to display error message for end user?

      if (validate) {
        this.disableButton = true;
        await this.$store.dispatch(
          "auth/requestSignupWeb",
          { email: this.form.email },
          { root: true }
        );

        if (this.$store.getters["auth/error"]) {
          this.formValidate.email = true;
          return;
        } else {
          // firs time may be error and last time is correct
          this.formValidate.email = false;
        }

        this.$store.dispatch(
          "auth/setSignupEmail",
          { email: this.form.email },
          { root: true }
        );
        // console.log(
        //   this.$store.getters["auth/email"]
        // );
        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          this.$router.push("/signup/verify?" + referralCode);
        } else {
          this.$router.push({ name: "SignupVerify" });
        }
        // validate on server too
        // const hasUser = await this.$store.dispatch(
        //   "user/hasUser",
        //   this.form.email,
        //   {
        //     root: true,
        //   }
        // );
        // this.formValidate.email = hasUser;
        // if (hasUser) {
        //   const errorMessage = `this email (${this.form.email}) has already been registered`;
        //   this.$store.commit("auth/errorMessage", errorMessage, { root: true });
        // } else {
        //   this.$store.dispatch(
        //     "CreateAccountForm/setCreateStep",
        //     "create_detail",
        //     { root: true }
        //   );
        // }
        this.disableButton = false;
      }
    },
  },
});
